import i18n from 'i18next';
// import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

function determineInitialLanguage() {
  const h = window.location.host;
  let lang = 'de';
  if (h.indexOf('minhaautocaravana.com') !== -1) {
    lang = 'pt';
  }
  return lang;
}

i18n
  .use(LanguageDetector)
  //.use(backend)
  .use(initReactI18next)
  .init({
    whitelist: ['de','pt','en','fr'],//'ru'],
    // lng can also be set through query param '?lng=LANGUAGE'
    // lng: determineInitialLanguage(),
    fallbackLng: determineInitialLanguage(),
    supportedLngs: ['de','pt','en','fr'],//'ru'],
    debug: false,

    resources: {
      'de': {
        "common": require('./i18n/de/common.json'),
        "home": require('./i18n/de/home.json'),
        "v": require('./i18n/de/v.json')
      },
      'pt': {
        "common": require('./i18n/pt/common.json'),
        "home": require('./i18n/pt/home.json'),
        "v": require('./i18n/pt/v.json')
      },
      'en': {
        "common": require('./i18n/en/common.json'),
        "home": require('./i18n/en/home.json'),
        "v": require('./i18n/en/v.json')
      },
      'fr': {
        "common": require('./i18n/fr/common.json'),
        "home": require('./i18n/fr/home.json'),
        "v": require('./i18n/fr/v.json')
      },
      'ru': {
        "common": require('./i18n/ru/common.json'),
        "home": require('./i18n/ru/home.json'),
        "v": require('./i18n/ru/v.json')
      }
    },

    // have a common namespace used around the full app
    ns: ['common','v', 'home'],
    defaultNS: 'common',

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false, // not needed for react!!
//      formatSeparator: ','
    },

//    saveMissing: true,

    returnEmptyString: true,

/*
    backend: {
      loadPath: '/i18n/{{lng}}/{{ns}}.json',
//      addPath: '/i18n/{{lng}}/{{ns}}.json',
//      loadPath: 'https://dtqrjipnh5.execute-api.eu-west-1.amazonaws.com/Stage/manage_translations',
//      addPath: 'https://dtqrjipnh5.execute-api.eu-west-1.amazonaws.com/Stage/manage_translations/{{lng}}/{{ns}}',
//      crossDomain: true,
    },
*/
    react: {
      useSuspense: true
    }
  });

export default i18n;