import moment from 'moment';
import {isAfterDay, isBeforeDay} from './dateUtils'; 

export function lastDayOfSeasons(seasons) {
  if (!seasons || !Array.isArray(seasons)) {
    return null;
  }
  let lastDayAllSeasons;
  // walking through all camper and check the last element since the array is sorted
  for (let i=0; i<seasons.length;i++) {
    let lastDaySeason = moment(seasons[i].end,'YYYY-MM-DD');
    if (isAfterDay(lastDaySeason,lastDayAllSeasons)) {
      lastDayAllSeasons = lastDaySeason;
    }
  } 
  return lastDayAllSeasons;
}

export function slicedSeasons(seasons, numberOfNextSeasons) {
  const seasonsCopy = [...seasons];
  const futureSeasons = seasonsCopy.filter(s => isAfterDay(moment(s.end,'YYYY-MM-DD'),moment())); 
  // sort before slicing
  return futureSeasons.sort((a, b) => {
    if (a.start<b.start) {
      return -1;
    }
    if (a.start>b.start) {
      return 1;
    }
    return 0;
  }).slice(0,numberOfNextSeasons);
}

export function ratesToSeasons(rates, seasons, refDate = moment()) {
  // this function adds the rate information to the given seasons
  const valRates = validRates(rates, refDate);
  if (!valRates) return false;
  const seasonsCopy = [...seasons];
  return seasonsCopy.map(s => {
    for(let i=0;i<valRates.length;i++) {
      if (!isBeforeDay(moment(s.start,'YYYY-MM-DD'),moment(valRates[i].validFrom,'YYYY-MM-DD')) 
          && !isAfterDay(moment(s.end,'YYYY-MM-DD'),moment(valRates[i].validTo,'YYYY-MM-DD'))) {
        let selectedRates = valRates[i].rates;
        for(let j=0;j<selectedRates.length;j++) {
          if (s.season === selectedRates[j].season) {
            s.rate = selectedRates[j];
            break;
          }
        }
      }
    }
    return s;
  });
}

export function validRates(rates, refDate) {
  if (!Array.isArray(rates) ) return false;

  return rates.filter(r=>r.validTo>=refDate.format('YYYY-MM-DD')).sort((a, b) => {
    if (a.validFrom<b.validFrom) return -1;
    if (a.validFrom>b.validFrom) return 1;
    return 0;
  });
}

export function getRelevantSeasons(startDate, endDate, ratedSeasons, specialOffers=[], camperId=null) {
    // this function determines all affected seasons for the given start and end date
    // the returned structure is:
    // {
    //     numDaysTotal: integer,
    //     seasons: [
    //         {
    //             name: string,
    //             fromDate: moment.Object,
    //             toDate: moment.Object,
    //             numDays: integer,
    //             dailyRate: decimal,
    //             stdRate: decimal
    //         }
    //     ]
    // }
    
    // check inputs
    if (!moment.isMoment(startDate) || !moment.isMoment(endDate) || !Array.isArray(ratedSeasons)) return false;
    
    let nDaysTotal = endDate.diff(startDate, 'days');
    let relevantRatedSeasons = [];
    let fromDate, toDate;

    if (!nDaysTotal) {
      return [];
    }
    
    // determine for all available seasons
    ratedSeasons.forEach((season)=> {
      // check, if season is affected
      // start, end both within a season
      if (endDate.isSameOrBefore( moment(season['end']), 'day')  && 
        startDate.isSameOrAfter(moment(season['start']), 'day')) {
        fromDate = startDate;
        toDate = endDate;
      }
      // only startDate within a season
      else if ((startDate.isSameOrAfter(moment(season['start']), 'day') && startDate.isSameOrBefore(moment(season['end']), 'day')) && endDate.isAfter(moment(season['end']), 'day') ) {
        fromDate = moment(startDate.format('YYYY-MM-DD'));
        toDate = moment(season['end']);
      }
      // only endDate within a season
      else if ((endDate.isSameOrAfter( moment(season['start']), 'day') && endDate.isSameOrBefore(moment(season['end']), 'day')) && startDate.isBefore(moment(season['start']), 'day') ) {
        fromDate = moment(season['start']);
        toDate = moment(endDate.format('YYYY-MM-DD'));
      }
      // a complete season is within start and end 
      else if (endDate.isSameOrAfter( moment(season['end']), 'day') && startDate.isSameOrBefore(moment(season['start']), 'day') ) {
        fromDate = moment(season['start']);
        toDate = moment(season['end']);
      }
      else
        return;

      const dailyRate = season['rate'];
      dailyRate.stdRate = dailyRate.rate;

      relevantRatedSeasons.push({
        season: {
          name: season['season'],
          fromDate: fromDate,
          toDate: toDate,
          dailyRate: dailyRate,
        },
        numDays: toDate.diff(fromDate, 'days') + 1,
        numDaysTotal: nDaysTotal + 1,
      });
    });
    
    // check if there are specialOffers and if so return a special seasons array
    if (camperId && specialOffers.length > 0) {
      // check if dates and camper match with an offer
      // filter by camperId, minDays, date, and validity
      const specialOffer = specialOffers.filter(r => (
        (r.camperId===camperId)
        // start and end must be within the from and to of the special offer
        && (
            startDate.isSameOrAfter(moment(r.fromDate,'YYYY-MM-DD'), 'day')
            && endDate.isSameOrBefore(moment(r.toDate,'YYYY-MM-DD'), 'day')
        )
        // double check min days in case the start and end are outside the special offer
        && ((endDate.diff(startDate, 'days') + 1) >= parseInt(r.minDays))
        // the offer must currently be valid
        && (
            moment().isSameOrBefore(moment(r.validTo), 'day')
            && moment().isSameOrAfter(moment(r.validFrom), 'day')
        )
      ));

      if (specialOffer && specialOffer.length > 0) {
        const specialSeason = 'special'

        // determine the highest standard rate
        const highestStdRate = relevantRatedSeasons
          .map(i=>i.season.dailyRate.rate)
          .reduce( (val, current) => (val>current) ? val : current, 0 );
        
        return [{
          numDays: nDaysTotal + 1,
          numDaysTotal: nDaysTotal + 1,
          season: {
            fromDate: startDate,
            toDate: endDate,
            dailyRate: {
              rate: specialOffer[0].dailyRate,
              stdRate: highestStdRate,
              season: specialSeason,
              minDays: specialOffer[0].minDays
            },
            name: specialSeason
          }
        }];
      }
    }

    // quality check if the determined seasons cover the range from start to end
    let daysOfSeasons = relevantRatedSeasons
      .map(i=>i.numDays)
      .reduce( (sum, current) => sum + current, 0 );
    if (daysOfSeasons !== nDaysTotal + 1) {
      return false;
    }

    return relevantRatedSeasons;
}
