import React from 'react';
import Rating from 'react-rating';

export function StarSymbols() {
    return (
        <svg id="svg-source" style={{"display": "none"}} version="1.1" xmlns="http://www.w3.org/2000/svg">
            <defs>
                <symbol id="icon-star-empty" viewBox="0 0 1024 1024">
                    <title>star-empty</title>
                    <path className="path1" d="M1024 397.050l-353.78-51.408-158.22-320.582-158.216 320.582-353.784 51.408 256 249.538-60.432 352.352 316.432-166.358 316.432 166.358-60.434-352.352 256.002-249.538zM512 753.498l-223.462 117.48 42.676-248.83-180.786-176.222 249.84-36.304 111.732-226.396 111.736 226.396 249.836 36.304-180.788 176.222 42.678 248.83-223.462-117.48z"></path>
                </symbol>
                <symbol id="icon-star-full" viewBox="0 0 1024 1024">
                    <title>star-full</title>
                    <path className="path1" d="M1024 397.050l-353.78-51.408-158.22-320.582-158.216 320.582-353.784 51.408 256 249.538-60.432 352.352 316.432-166.358 316.432 166.358-60.434-352.352 256.002-249.538z"></path>
                </symbol>
            </defs>
        </svg>
    );
}

export function ReviewStars(props) {
    
    const {stars} = props;
    const SVGIcon = (props) =>
        <svg className={props.className} pointerEvents="none">
            <use xlinkHref={props.href} />
        </svg>;
    
    return (
        <Rating 
            initialRating={stars} 
            readonly
            emptySymbol={<SVGIcon href="#icon-star-empty" className="reviewStar" />}
            fullSymbol={<SVGIcon href="#icon-star-full" className="reviewStar" />}                        
        />
    );
}
