import React from 'react';
// import avatarImage from '../../img/profile_avatar.png';
import ReadMore from '../../components/ReadMore/ReadMore';
import {ReviewStars} from '../../components/Reviews/ReviewStars';
import * as moment from 'moment';

export function Review(props) {
    const {data, isLast, t} = props;
    return (
        <div className="reviewItem" key={'i-'+data['reviewid']} itemProp="review" itemScope itemType="http://schema.org/Review">
            <div itemScope itemProp="reviewRating" itemType="http://schema.org/Rating">
                <meta content="1" itemProp="worstRating" />
                <meta content="5" itemProp="bestRating" />
                <meta content={data.stars} itemProp="ratingValue" />
            </div>

            <div className="row">
                {/*<div className="col-2 col-xs-3 pr-0">
                    <div className="reviewItemAvatar">
                        <img src={avatarImage} alt={data.name} />
                    </div>
                </div>*/}
                <div className="col">
                    <div className="reviewItemContent">
                        <div className="reviewItemContentHeader">
                            <div className="reviewItemContentHeaderLeft">
                                <span className="reviewAuthor" itemProp="author" itemScope={true} itemType="https://schema.org/Person">
                                    <span itemProp="name">{data.name}</span>
                                </span>
                                <br />
                                <meta itemProp="datePublished" content={moment(data.date).format("YYYY-MM-DD")} />
                                <span className="text-muted font-italic">{moment(data.date).format("MMM YYYY")}</span>
                            </div>                            
                        </div>
                        <div className="pt-1 pb-2"><ReviewStars stars={data.stars} /></div>
                        <div className="reviewItemContentBody" itemProp="reviewBody">
                            <ReadMore
                                charLimit={140}
                                readMoreText={t('readMore')}
                                readLessText={t('readLess')} 
                                text={data.text}
                            />                         
                        </div>
                    </div>
                </div>
            </div>  
            {(isLast) ? null : <hr /> }
        </div>
    );
}
