import React, { useState } from 'react';
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink } from 'reactstrap';
import './Navigation.css';
// together with the exact_to it brings the active class
import { NavLink as RRNavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {ChangeLanguageButton} from '../../App';
import SocialMediaIcons from "../SocialMediaIcons/SocialMediaIcons";

function Navigation(props) {
  const [collapsed, setCollapsed] = useState(true);
  const toggleNavbar = () => setCollapsed(!collapsed);

  const { t } = useTranslation();

  // if (!i18n) return null;
  
  return (
    <Navbar className="navbar-dark navigation" color="dark" expand="md">
      <NavbarBrand href="/" className="mr-auto">
        <FontAwesomeIcon icon={["fas", "home"]} />
      </NavbarBrand>
      <div className="d-block d-md-none text-right"><ChangeLanguageButton {...props} second /></div>
      <NavbarToggler onClick={toggleNavbar} className="mr-2" />
      <Collapse isOpen={!collapsed} navbar>
        <Nav navbar>
          <NavItem>
            <NavLink onClick={toggleNavbar} to="/vehicles" tag={RRNavLink} href="/vehicles">{t('common:caravans')}</NavLink>
          </NavItem>
          <NavItem>
            <NavLink onClick={toggleNavbar} to="/faq" tag={RRNavLink} href="/faq">{t('common:faq')}</NavLink>
          </NavItem>
          <NavItem>
            <NavLink onClick={toggleNavbar} to="/reviews" tag={RRNavLink} href="/reviews">{t('common:reviews')}</NavLink>
          </NavItem>
          <NavItem>
              <div className="d-none d-md-block">
                <SocialMediaIcons nav width="23px" height="23px" />
              </div>
              <div onClick={toggleNavbar} className="row d-block d-md-none border-top border-secondary py-2 justify-content-center">
                <SocialMediaIcons width="23px" height="23px" />
              </div>
          </NavItem>
        </Nav>
        <div className="d-none d-md-block text-right"><ChangeLanguageButton {...props} /></div>
      </Collapse>
    </Navbar>
  );
}
export default Navigation;