import React from 'react';
import { Helmet } from 'react-helmet-async';
const app_domain = process.env.REACT_APP_DOMAIN;

export default function SEO({title, description, site_name="minhaautocaravana.com", domain=app_domain, url="", image="logo_minhaautocaravana.jpg"}) {
    return (
        <Helmet prioritizeSeoTags>
            <title>{title}</title>
            <meta name='description' content={description} />
            <link rel="canonical" href={'http://' + domain + url} />
            <meta property="og:site_name" content={site_name} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:url" content={'https://' + domain + url} />
            <meta property="og:image" content={'https://' + domain + image} />
            <meta property="twitter:card" content="summary" />
            <meta property="twitter:domain" content={'https://' + domain} />
            <meta property="twitter:title" content={title} />
            <meta property="twitter:description" content={description} />
        </Helmet>
    )
}