import React from 'react';
import './SocialMediaIcons.css';
import facebookIcon from "../../icons/facebook.svg";
import instagramIcon from "../../icons/instagram.svg";
import whatsappIcon from "../../icons/whatsapp.svg";

function SocialMediaIcons(props) {
    const {width, height, nav} = props;
    const navStyle = (nav) ? "nav-sm-icons" : "";

    return (
        <React.Fragment>
            <ul className={"list-inline " + navStyle}>
              <li className="list-inline-item px-2">
                <a className="text-secondary" href="https://facebook.com/WohnmobilPortugal" target="_blank" rel="noreferrer">
                  <img
                    src={facebookIcon}
                    alt="Follow us on facebook"
                    title="Follow us on facebook"
                    width={width}
                    height={height}
                  />
                </a>
              </li>
              <li className="list-inline-item px-2">
                <a className="text-secondary" href="https://www.instagram.com/invites/contact/?i=1m8rsr6bj04mr&utm_content=3g3niiq" target="_blank" rel="noreferrer">
                  <img
                    src={instagramIcon}
                    alt="Follow us on Instagram"
                    title="Follow us on Instagram"
                    width={width}
                    height={height}
                  />
                </a>
              </li>
              <li className="list-inline-item px-2">
                <a className="text-secondary" href="https://api.whatsapp.com/send?phone=351961374649&text=" target="_blank" rel="noreferrer">
                  <img
                    src={whatsappIcon}
                    alt="Start a chat in WhatsApp"
                    title="Start a chat in WhatsApp"
                    width={width}
                    height={height}
                  />
                </a>
              </li>
            </ul>
        </React.Fragment>
    )
}

export default SocialMediaIcons;