import React from 'react';
import './Jumbotron.css';

function Jumbotron (props) {
  const headline = props.headline;
  const subtitle = props.subtitle;

  return (
    <section className="jumbotron-fluid bg-img">
      <div className="container text-center jumbo">
        <h1 className="bigger">{headline}</h1>
        <p className="big">{subtitle}</p>
        <div className="container">
          <div className="row justify-content-center">
            {props.children}
          </div>
        </div>
      </div>
    </section>
  );
}
export default Jumbotron;