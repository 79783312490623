import moment from 'moment';

export function isSameDay(a, b) {
  if (!moment.isMoment(a) || !moment.isMoment(b)) return false; // Compare least significant, most likely to change units first
  // Moment's isSame clones moment inputs and is a tad slow
  return a.date() === b.date() && a.month() === b.month() && a.year() === b.year();
}

export function isBeforeDay(a, b) {
  if (!moment.isMoment(a) || !moment.isMoment(b)) return false;
  var aYear = a.year();
  var aMonth = a.month();
  var bYear = b.year();
  var bMonth = b.month();
  var isSameYear = aYear === bYear;
  var isSameMonth = aMonth === bMonth;
  if (isSameYear && isSameMonth) return a.date() < b.date();
  if (isSameYear) return aMonth < bMonth;
  return aYear < bYear;
}

export function isAfterDay(a, b) {
  if (!moment.isMoment(a) || !moment.isMoment(b)) return false;
  return !(0, isBeforeDay)(a, b) && !(0, isSameDay)(a, b);
}

export function isDayBlocked(day, blockedDays) {
  for (let i=0;i<blockedDays.length;i++) {
    const range = blockedDays[i];
    if (!isAfterDay(day, moment(range.toDate)) && !isBeforeDay(day, moment(range.fromDate))) {
      return true;
    }
  }
  return false;
}

export function isValidRange(startDate, endDate, blockedDays, seasons) {  
  if (!startDate) {
    return true;
  }
  let p = moment(startDate.format('YYYY-MM-DD'));
  let valid = isBeforeDay(p, endDate);
  // if no blockedDays, range daesn't need further assessment
  if (!blockedDays) {
    return valid;
  }
  //console.log(blockedDays);
  while (valid && !isAfterDay(p, endDate)) {
    //console.log('check: ', p);
    valid = !isDayBlocked(p, blockedDays);
    p.add(1, 'd');
    //console.log('next: ', p);
  }
  //console.log('valid: ', valid);
  return valid;
}

export function blockedDaysFromBlockedDates(blockedDates, camper) {
  if (!blockedDates)
    return null;
  blockedDates.sort((a,b) => {
    if (a.fromDate < b.fromDate)
      return -1;
    if (a.fromDate > b.fromDate)
      return 1;
    return 0;
  });

  const key = camper['camperId'];
  let invalidDates = [];
  blockedDates.forEach((item) => {
    if (key && item.camperId!==key) {
      //console.log('not matching: ', key, item.camperId);
      return;
    }
    invalidDates.push({
      'camperId':item.camperId,
      'fromDate':item.fromDate,
      'toDate':item.toDate
    });
  });
  return invalidDates;
}
