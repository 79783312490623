import React from 'react';
import './Footer.css';
import { withTranslation } from 'react-i18next';
import * as moment from 'moment';
import { Link } from 'react-router-dom';
import SocialMediaIcons from "../SocialMediaIcons/SocialMediaIcons";

function Footer(props) {
  const {t, tReady} = props;

  if (!tReady) return null;

  return (
    <footer className="page-footer font-small">
      <div className="container">
        <div className="row">
          <div className="col p-3">
            <Link className="text-muted" to="/imprint">{t('common:imprint')}</Link>
          </div>
          <div className="col p-3">
              <Link className="text-muted" to="/terms">{t('common:termsconditions')}</Link>
          </div>
          <div className="col p-3">
              <Link className="text-muted" to="/privacy">{t('common:privacy')}</Link>
          </div>
        </div>
      </div>
      <div className="footer-copyright py-3">
        <SocialMediaIcons width="30px" height="30px" />
      </div>
      <div className="footer-copyright pb-3 text-muted">
        {'© 2018-' + moment().format('YYYY') + ' '}<Link className="text-secondary" to="/">{t('common:MeinWohnmobil.com')}</Link>
      </div>
    </footer>
  );
}

export default withTranslation()(Footer);