import React from 'react';
import PropTypes from 'prop-types';

function replaceLinebreaks(text, trim=true) {
  if (!text) {
    text = '';
  }
  
  // remove linebreaks at the end of the text
  if (trim) {
    text = text.trim().replace(/\n*$/, '');
  }
  
  const lines = text.split("\n");
  const replacedLinebreaks = lines.map((line, i) => {
    const br = <br />;
    // do not print the last linebreak
    return (<React.Fragment key={i}>{line}{(i!==lines.length-1) ? br : ''}</React.Fragment>);
  });
  return <React.Fragment>{replacedLinebreaks}</React.Fragment>;
}

function ReadMore(props) {
  const {text, charLimit, readMoreText, readLessText} = props;
  
  const [limit, setLimit] = React.useState(charLimit);

  const getReadMoreContent = () => {
    const charLimit = limit;
    
    if (!text)
      return null;
    
    if (text.length > charLimit) {
      return (<p><span className="short-text">
        {replaceLinebreaks(text.substr(0, charLimit), true)}...&nbsp;
        <span
          className="readMoreText"
          style={{ color: '#007bff', cursor: 'pointer' }}
          role="presentation"
          onClick={showLongText}
        >
          {readMoreText}
        </span>
      </span></p>);
    } else if (text.length < charLimit) {
      return (<p><span className="short-text">
        {replaceLinebreaks(text)}
      </span></p>);
    }
    
    return (<p><span className="short-text">
      {replaceLinebreaks(text)}
      <span
        className="readMoreText"
        style={{ color: '#007bff', cursor: 'pointer' }}
        role="presentation"
        onClick={showShortText}
      >
        &nbsp;{readLessText}
      </span>
    </span></p>);
  };

  const showLongText = () => {
    setLimit(text.length);
    getReadMoreContent();
  };

  const showShortText = () => {
    setLimit(charLimit);
    getReadMoreContent();
  };

  return (
      <div>
        {getReadMoreContent()}
      </div>
  );
}

ReadMore.propTypes = {
  charLimit: PropTypes.number,
  readMoreText: PropTypes.string,
  readLessText: PropTypes.string
  //children: PropTypes.string
};
ReadMore.defaultProps = {
  charLimit: 150,
  readMoreText: 'Read more',
  readLessText: 'Read less'
};
export default ReadMore;